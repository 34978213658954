/* © Microsoft Corporation. All rights reserved. */

body,
html,
.Root {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff9f3;
}

.ui-provider {
  height: 0;
}

.logo-wrapper {
  width: 100%;
  position:absolute; 
  top: 0;
}

#logo{
  height: 35px;
  width: 153px;
  margin: 20px 30px;
}
.wrapper > div:not(.logo-wrapper) { height: 100% }